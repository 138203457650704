import { AppInfoQueryDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

const Version = () => {
  const { data, loading } = useQ(AppInfoQueryDoc, { fetchPolicy: 'network-only' });
  const commitSha = '08da0a85';
  const commitTag = '2.24.0-rc.11';
  const shaOrTag = commitTag.length ? commitTag : commitSha;
  const displayed = shaOrTag.length && !shaOrTag.includes('PLACEHOLDER') ? shaOrTag : 'Dev';

  if (!data || loading) return null;

  const { apiVersion } = data;

  return (
    <div className="text-center bg-gray-100 text-gray-400 font-thin">
      <span>Client Version:</span>

      <span className="ml-0.5">{displayed} |</span>

      <span className="ml-0.5">API Version:</span>

      <span className="ml-0.5">{apiVersion}</span>
    </div>
  );
};

export default Version;
